<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2 p-0" max-width="1200" outlined>
          <v-container class="py-0">
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Número afiliado -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Número de afiliado"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      v-model="numeroAfiliado"
                      autocomplete="off"
                      :rules="[rules.maxLength(numeroAfiliado, 15)]"
                    ></v-text-field>
                  </v-col>
                  <!-- Apellido/s -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Apellido/s"
                      v-model="apellido"
                      autocomplete="off"
                      :rules="[rules.maxLength(apellido, 45)]"
                    ></v-text-field>
                  </v-col>
                  <!-- Nombre/s -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Nombre/s"
                      v-model="nombre"
                      autocomplete="off"
                      :rules="[rules.maxLength(nombre, 45)]"
                    ></v-text-field>
                  </v-col>
                  <!-- Número de documento -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="N° de documento"
                      v-model="docAfiliado"
                      v-mask="['#.###.###', '##.###.###']"
                      autocomplete="off"
                      :rules="[rules.minLength(docAfiliado, 7)]"
                    ></v-text-field>
                  </v-col>
                  <!-- Número de CUIL/CUIT -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      dense
                      @change="validarCUIL(cuilOrCuit)"
                      label="CUIL/CUIT"
                      v-model="cuilOrCuit"
                      v-mask="'##-########-#'"
                      autocomplete="off"
                      :rules="
                        [rules.minLength(cuilOrCuit, 12)].concat([
                          rules.maxLength(cuilOrCuit, 13)
                        ])
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Fecha -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="fechaAlta"
                      v-model="menuFechaAltaAfiliado"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedfechaAltaAfiliadoFormatted"
                          label="Consulta a la fecha *"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaAltaAfiliado = parseDateToIso(
                              computedfechaAltaAfiliadoFormatted
                            )
                          "
                          :rules="
                            (computedfechaAltaAfiliadoFormatted
                              ? rules.validDate
                              : []
                            ).concat(rules.required)
                          "
                          dense
                          outlined
                          autocomplete="off"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaAltaAfiliado"
                        @change="
                          computedfechaAltaAfiliadoFormatted = formatDate(
                            fechaAltaAfiliado
                          )
                        "
                        no-title
                        scrollable
                        @input="menuFechaAltaAfiliado = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Añadir todo el grupo familiar -->
                  <v-col cols="12" sm="6" md="3" class="p-0">
                    <v-switch
                      class="mt-1"
                      v-model="addGrupoFamiliar"
                      label="Incluir todo el grupo familiar"
                      item-text="value"
                      item-value="id"
                      dense
                      outlined
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col class="py-0" cols="12" md="6" align="center" >
                    <v-subheader>(*) Datos obligatorios</v-subheader>
                  </v-col>
                  <v-col cols="12" md="6" class="text-right pb-0 pt-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="afiliadoId"
            :items="resultsAfiliados"
            ref="afiliados-data-table"
            :loading="loading"
            class="elevation-1"
            :search="search"
            :items-per-page="itemsPerPage"
            :page.sync="currentPage"
          >
            <template v-slot:[`header.estadoBenef`]="{ header }">
              {{
                computedfechaAltaAfiliadoFormatted != null
                  ? header.text + " " + computedfechaAltaAfiliadoFormatted
                  : header.text
              }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="5" md="5">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" align="end" v-if="canCreate">
                    <div v-if="itemsConvenioSinPadron.length > 0">
                      <v-btn
                        color="primary"
                        class="to-right no-upper-case"
                        @click="afiConveniosSinPadron()"
                      >
                        Afiliados a convenios sin padrón
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="2" md="3" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="nuevoAfiliado()"
                      id="btn-nuevo-titular-gestion-afiliados"
                    >
                      Nuevo titular
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu
                transition="scale-transition"
                bottom
                origin="center center"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip left v-if="canSeeMovements">
                    <template v-slot:activator="{ on: tooltip }">
                      <v-icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        size="25"
                        @click="seeMovements(item)"
                      >
                        {{ item.esAlta ? movements : "" }}
                      </v-icon>
                    </template>
                    <span>Ver movimientos</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in movimientos"
                    :key="item.title"
                    link
                    @click="openModal(item.id)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                transition="scale-transition"
                bottom
                origin="center center"
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip left v-if="canSeeMoreOptions">
                    <template v-slot:activator="{ on: tooltip }">
                      <v-icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        size="20"
                        @click="seeOptions(item)"
                      >
                        {{ item.esAlta ? optionsIcon : "" }}
                      </v-icon>
                    </template>
                    <span>Ver más acciones</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in opciones"
                    :key="item.id"
                    link
                    @click="openModalOpciones(item.id)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tooltip left v-if="canGenerateToken">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      toggleopenModalGenerarToken(item.benId, item.nombre)
                    "
                  >
                    {{ tokenIcon }}
                  </v-icon>
                </template>
                <span>Generar token</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit || canSee">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      editAfiliado(
                        item.benId,
                        item.esAlta,
                        item.convenio.value,
                        item.esTitular
                      )
                    "
                  >
                    {{ item.esAlta && canEdit ? editIcon : seeIcon }}
                  </v-icon>
                </template>
                <span>{{
                  item.esAlta && canEdit ? "Editar afiliado" : "Ver afiliado"
                }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <DeleteDialog
            :titleProp="titleDelete"
            :isLoading="loadingDeleteBtn"
            :openDelete.sync="showDeleteModal"
            @onDeleteItem="confirmDelete()"
          />
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalGenerarToken"
      v-model="openModalGenerarToken"
      @keydown.esc="closeModal"
      max-width="60%"
      persistent
    >
      <GenerarTokenAfiliado
        :benId="benId"
        :benNom="afiliadoNom"
        @closeModalGenerarToken="closeModal"
      ></GenerarTokenAfiliado>
    </v-dialog>
    <v-dialog
      v-if="openModalNuevoMovimiento"
      v-model="openModalNuevoMovimiento"
      @keydown.esc="closeModal"
      max-width="1200px"
      persistent
    >
      <NuevoMovimientoAfiliado
        :benId="benId"
        :fechaConsultaAfi="fechaConsultaAfi"
        :isBaja="isBaja"
        :canAddMovimientoPlanesTodos="canAddMovimientoPlanesTodos"
        @closeModalNuevoMovimiento="closeModal"
      ></NuevoMovimientoAfiliado>
    </v-dialog>
    <v-dialog
      v-if="openModalBajaAfiliados"
      v-model="openModalBajaAfiliados"
      @keydown.esc="closeModalRegistrarBaja"
      max-width="1100px"
      persistent
    >
      <RegistrarBaja
        :benId="benId"
        :fechaConsultaAfi="fechaConsultaAfi"
        @closeModal="closeModal"
      ></RegistrarBaja>
    </v-dialog>
    <v-dialog
      v-if="openModalForzarMovPadronSss"
      v-model="openModalForzarMovPadronSss"
      @keydown.esc="closeModal"
      max-width="1100px"
      persistent
    >
      <ForzarMovEnPadronSss
        :benId="benId"
        :fechaConsultaAfi="fechaConsultaAfi"
        @closeForzar="closeModal"
      ></ForzarMovEnPadronSss>
    </v-dialog>
    <v-dialog
      v-if="openModalGestionMovimientos"
      v-model="openModalGestionMovimientos"
      @keydown.esc="closeModal"
      max-width="65%"
      persistent
    >
      <GestionMovimientosAfiliado
        :benId="benId"
        :afiliadoNom="afiliadoNom"
        @closeGestionMovimientos="closeModal"
      ></GestionMovimientosAfiliado>
    </v-dialog>
    <v-dialog
      v-if="openModalUserCodificado"
      v-model="openModalUserCodificado"
      @keydown.esc="closeModalUserCodificado"
      max-width="1100px"
      persistent
    >
      <GestionAfiliadosCodificados
        :benId="benId"
        @closeModalUserCodificado="closeModalUserCodificado"
      ></GestionAfiliadosCodificados>
    </v-dialog>
    <v-dialog
      v-if="modalUnificarAfiliado"
      v-model="modalUnificarAfiliado"
      @keydown.esc="toggleModalUnificarAfiliado"
      max-width="65%"
      persistent
    >
      <UnificacionesAfiliado
        :benId="benId"
        @toggleModalUnificarAfiliado="toggleModalUnificarAfiliado($event)"
      ></UnificacionesAfiliado>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import validateCUIL from "@/utils/helpers/validateCUIL";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mask } from "vue-the-mask";
import RegistrarBaja from "@/components/modules/afiliaciones/afiliados/RegistrarBajaAfiliado.vue";
import NuevoMovimientoAfiliado from "@/components/modules/afiliaciones/afiliados/NuevoMovimientoAfiliado.vue";
import ForzarMovEnPadronSss from "@/components/modules/afiliaciones/afiliados/ForzarMovEnPadronSss.vue";
import GestionMovimientosAfiliado from "@/components/modules/afiliaciones/afiliados/GestionMovimientosAfiliado.vue";
import GestionAfiliadosCodificados from "@/components/modules/afiliaciones/afiliados/GestionAfiliadosCodificados.vue";
import GenerarTokenAfiliado from "@/components/modules/afiliaciones/afiliados/GenerarTokenAfiliado.vue";
import UnificacionesAfiliado from "@/components/modules/afiliaciones/afiliados/UnificacionesAfiliado.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "GestionAfiliados",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    RegistrarBaja,
    NuevoMovimientoAfiliado,
    ForzarMovEnPadronSss,
    GestionMovimientosAfiliado,
    GestionAfiliadosCodificados,
    GenerarTokenAfiliado,
    UnificacionesAfiliado,
    Ayuda
  },
  data: vm => ({
    title: "Gestión de afiliados",
    filtersApplyed: [],
    titleDelete: "¿Eliminar afiliado?",
    idToDelete: null,
    search: "",
    showExpand: false,
    showHelp: false,
    optionCode: enums.webSiteOptions.GESTION_AFILIADOS,
    showIcon: true,
    fechaAltaAfiliado: new Date().toISOString().substr(0, 10),
    menuFechaAltaAfiliado: null,
    deleteIcon: enums.icons.DELETE,
    editIcon: enums.icons.EDIT,
    seeIcon: enums.icons.SEE,
    usuarioCodificado: enums.icons.USER_SQUARE,
    afiliadoNom: null,
    movimientos: [],
    opciones: [],
    movements: enums.icons.MORE_ITEMS,
    optionsIcon: enums.icons.MORE_OPTIONS,
    loadingDeleteBtn: false,
    searchIcon: enums.icons.SEARCH,
    headers: [
      {
        text: "Apellido/s y nombre/s",
        align: "start",
        sortable: false,
        value: "nombre"
      },
      {
        text: "Número de afiliado",
        sortable: false,
        value: "nroAfi"
      },
      {
        text: "Parentesco",
        sortable: false,
        value: "parentesco"
      },
      {
        text: "Convenio",
        sortable: false,
        value: "convenio.value"
      },
      {
        text: "Plan",
        sortable: false,
        value: "plan"
      },
      {
        text: "Estado a la fecha",
        sortable: false,
        value: "estadoBenef"
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end",
        width: "5%"
      }
    ],
    addGrupoFamiliar: false,
    movementsOn: false,
    resultsAfiliados: [],
    computedfechaAltaAfiliadoFormatted: vm.formatDate(
      new Date().toISOString().substr(0, 10)
    ),
    showFilters: true,
    loading: false,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    tokenIcon: enums.icons.SHIELD_KEY_ICON,
    isFormValid: true,
    calendarIcon: enums.icons.CALENDAR,
    numeroAfiliado: null,
    rules: rules,
    apellido: null,
    nombre: null,
    agentesCtaLoading: false,
    allowedActions: null,
    docAfiliado: null,
    cuilOrCuit: null,
    canEdit: false,
    canDelete: false,
    nuevoAfiliadoTitular: false,
    canCreate: false,
    canSee: false,
    showDeleteModal: false,
    canGoToReferences: false,
    canAddAfiCodificado: false,
    canSeeMovements: false,
    canAddMovimientoPlanesTodos: false,
    fechaConsultaAfi: null,
    newAfiTitular: null,
    fromEdit: false,
    filtersFromEdit: [],
    openModalBajaAfiliados: false,
    openModalUserCodificado: false,
    openModalForzarMovPadronSss: false,
    closeModalRegistrarBaja: false,
    closeModalForzarMovPadronSss: false,
    openModalNuevoMovimiento: false,
    isBaja: false,
    openModalGestionMovimientos: false,
    canSeeGestionMovimientos: false,
    itemsConvenioSinPadron: [],
    canEditVtoCredencial: false,
    canGenerateToken: false,
    openModalGenerarToken: false,
    benId: null,
    benGrId: null,
    modalUnificarAfiliado: false,
    canSeeMoreOptions: false,
    itemsPerPage: 10,
    currentPage: 1
  }),
  created() {
    if (this.$route.params.fromEdit) {
      this.fromEdit = this.$route.params.fromEdit;
      this.filtersFromEdit = this.$store.state.filters;
      ({
        numero: this.numeroAfiliado,
        apellido: this.apellido,
        nombre: this.nombre,
        dni: this.docAfiliado,
        cuil: this.cuilOrCuit,
        incluirGrupoFam: this.addGrupoFamiliar
      } = this.filtersFromEdit);
      this.computedfechaAltaAfiliadoFormatted = this.formatDate(
        this.$store.state.filters.fecha
      );
      this.fechaAltaAfiliado = this.$store.state.filters.fecha;
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
      this.applyFilters();
    }
    if (this.$route.params.nuevoAfiliado) {
      this.nuevoAfiliadoTitular = true;
      this.nombre = this.$route.params.datosAfiliado.nombre;
      this.apellido = this.$route.params.datosAfiliado.apellido;
      this.docAfiliado = this.$route.params.datosAfiliado.dni;
      this.addGrupoFamiliar = true;
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
      this.applyFilters();
    }
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
    this.setConvenios();
  },
  methods: {
    ...mapActions({
      fetchAfiliados: "afiliaciones/fetchAfiliados",
      getConveniosSPadron: "afiliaciones/getConveniosSPadron",
      fetchAgentesCtaByQuery: "afiliaciones/fetchAgentesCtaByQuery",
      deleteAfiliadoById: "afiliaciones/deleteAfiliado",
      setAlert: "user/setAlert"
    }),

    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.EDITAR_AFILIADO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.VER_AFILIADO:
            this.canSee = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_AFILIADO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.IR_REFERENCIAS:
            this.opciones.push({ title: "Ir a datos complementarios", id: 1 });
            this.canSeeMoreOptions = true;
            break;
          case enums.modules.adminSistema.permissions.UNIFICACIONES_AFILIADO:
            this.opciones.push({ title: "Unificaciones", id: 2 });
            this.canSeeMoreOptions = true;
            break;
          case enums.modules.adminSistema.permissions.AFILIADO_CODIFICADO:
            this.opciones.push({ title: "Afiliado codificado", id: 3 });
            this.canSeeMoreOptions = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_AFILIADO:
            this.opciones.push({ title: "Eliminar", id: 4 });
            this.canSeeMoreOptions = true;
            break;
          case enums.modules.adminSistema.permissions.REGISTRAR_BAJA:
            this.movimientos.push({ title: "Dar de baja", id: 1 });
            this.canSeeMovements = true;
            break;
          case enums.modules.adminSistema.permissions
            .REGISTRAR_NUEVO_MOVIMIENTO:
            this.movimientos.push({ title: "Nuevo movimiento", id: 2 });
            this.canSeeMovements = true;
            break;
          case enums.modules.adminSistema.permissions.FORZAR_MOVIMIENTO:
            this.movimientos.push({
              title: "Forzar movimiento en padrón SSS",
              id: 3
            });
            this.canSeeMovements = true;
            break;
          case enums.modules.adminSistema.permissions.GESTION_MOVIMIENTOS:
            this.movimientos.push({
              title: "Gestión de movimientos",
              id: 4
            });
            this.canSeeGestionMovimientos = true;
            this.canSeeMovements = true;
            break;
          case enums.modules.adminSistema.permissions
            .REGISTRAR_NUEVO_MOVIMIENTO_PLANES_TODOS:
            this.canAddMovimientoPlanesTodos = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_VENCIMIENTO_CREDENCIAL:
            this.canEditVtoCredencial = true;
            break;
          case enums.modules.adminSistema.permissions.GENERAR_TOKEN_AFILIADO:
            this.canGenerateToken = true;
            break;
          default:
            break;
        }
      });
    },
    async setConvenios() {
      const conveniosSinPadron = await this.getConveniosSPadron();
      this.itemsConvenioSinPadron = conveniosSinPadron.data;
    },
    validarCUIL(cuil) {
      if (cuil.length == 0) {
        return (this.isFormValid = true);
      }
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        this.setAlert({
          type: "warning",
          message: "El CUIL ingresado es incorrecto."
        });
        this.isFormValid = false;
      }
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    resetForm() {
      this.numeroAfiliado = this.apellido = this.nombre = this.docAfiliado = this.cuilOrCuit = null;
      this.addGrupoFamiliar = false;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    modalUserCodificado(benId) {
      this.openModalUserCodificado = true;
      this.benId = benId;
    },
    closeModalUserCodificado() {
      this.openModalUserCodificado = false;
    },
    toggleModalUnificarAfiliado(response) {
      this.modalUnificarAfiliado = !this.modalUnificarAfiliado;
      if (response != null) {
        this.resetForm();
        this.showFilters = true;
        this.numeroAfiliado = response;
        this.applyFilters();
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    openModal(id) {
      switch (id) {
        case 1:
          this.openModalBajaAfiliados = true;
          this.fechaConsultaAfi = this.fechaAltaAfiliado;
          break;
        case 2:
          this.openModalNuevoMovimiento = true;
          this.fechaConsultaAfi = this.fechaAltaAfiliado;
          break;
        case 3:
          this.openModalForzarMovPadronSss = true;
          this.fechaConsultaAfi = this.fechaAltaAfiliado;
          break;
        case 4:
          this.openModalGestionMovimientos = true;
          break;
        default:
          break;
      }
    },
    openModalOpciones(id) {
      switch (id) {
        case 1:
          this.seeReferences(this.benId, this.benGrId);
          break;
        case 2:
          this.toggleModalUnificarAfiliado();
          break;
        case 3:
          this.modalUserCodificado(this.benId);
          break;
        case 4:
          this.deleteAfiliado(this.benId);
          break;
        default:
          break;
      }
    },
    closeModal() {
      this.openModalBajaAfiliados = false;
      this.openModalNuevoMovimiento = false;
      this.openModalForzarMovPadronSss = false;
      this.openModalGestionMovimientos = false;
      this.openModalGenerarToken = false;
      this.applyFilters();
    },
    async applyFilters() {
      this.loading = true;
      const filters = {
        numero: this.numeroAfiliado,
        apellido: this.apellido,
        nombre: this.nombre,
        dni: this.docAfiliado,
        cuil: this.cuilOrCuit,
        fecha: this.fechaAltaAfiliado,
        incluirGrupoFam: this.addGrupoFamiliar
      };
      this.customizeFiltersApplied();
      const data = {
        numero: this.numeroAfiliado,
        apellido: this.apellido,
        nombre: this.nombre,
        dni:
          this.docAfiliado && this.nuevoAfiliadoTitular == false
            ? this.docAfiliado.replaceAll(".", "")
            : this.docAfiliado,
        cuil: this.cuilOrCuit
          ? this.cuilOrCuit.replaceAll("-", "")
          : this.cuilOrCuit,
        fecha: this.fechaAltaAfiliado,
        incluirGrupoFam: this.addGrupoFamiliar
      };
      this.nuevoAfiliadoTitular = false;
      try {
        this.currentPage = 1;
        const response = await this.fetchAfiliados(data);
        this.resultsAfiliados = response;
        this.$store.state.filters = filters;
        if (this.resultsAfiliados.length > 0) {
          this.showFilters = false;
          this.showFiltersSelected = true;
        }
        this.resultsAfiliados.forEach(x => {
          if (x.convenio == null) {
            x.convenio = { value: "Sin dato" };
          }
          if (x.estadoBenef == null) {
            x.estadoBenef = "Sin dato";
          }
          if (x.plan == null) {
            x.plan = "Sin dato";
          }
        });
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.numeroAfiliado) {
        this.filtersApplyed.splice(0, 1, {
          key: "nroAfi",
          label: "Número de afiliado",
          model: this.numeroAfiliado
        });
      }
      if (this.apellido) {
        this.filtersApplyed.splice(1, 1, {
          key: "apellido",
          label: "Apellido/s",
          model: this.apellido.toUpperCase()
        });
      }
      if (this.nombre) {
        this.filtersApplyed.splice(2, 1, {
          key: "nombre",
          label: "Nombre/s",
          model: this.nombre.toUpperCase()
        });
      }
      if (this.docAfiliado) {
        this.filtersApplyed.splice(3, 1, {
          key: "dni",
          label: "DNI",
          model: this.docAfiliado
        });
      }
      if (this.cuilOrCuit) {
        this.filtersApplyed.splice(4, 1, {
          key: "cuil",
          label: "CUIL/CUIT",
          model: this.cuilOrCuit
        });
      }
      if (this.computedfechaAltaAfiliadoFormatted) {
        this.filtersApplyed.splice(5, 1, {
          key: "fecha",
          label: "Fecha de consulta",
          model: this.computedfechaAltaAfiliadoFormatted
        });
      }
      if (this.addGrupoFamiliar) {
        const incluirGF = "SÍ";
        this.filtersApplyed.splice(6, 1, {
          key: "incluirGrupoFam",
          label: "Incluye todo el grupo familiar",
          model: incluirGF
        });
      } else {
        const incluirGF = "NO";
        this.filtersApplyed.splice(6, 1, {
          key: "incluirGrupoFam",
          label: "Incluye todo el grupo familiar",
          model: incluirGF
        });
      }
    },
    nuevoAfiliado() {
      this.newAfiTitular = true;
      this.$router.push({
        name: "EditAfiliado",
        params: {
          newAfiTitular: this.newAfiTitular,
          canAddMovimientoPlanesTodos: this.canAddMovimientoPlanesTodos
        }
      });
    },
    afiConveniosSinPadron() {
      this.newAfiTitular = true;
      this.$router.push({
        name: "AfiliadosAConveniosSPadron",
        params: {
          newAfiTitular: this.newAfiTitular,
          canAddMovimientoPlanesTodos: this.canAddMovimientoPlanesTodos
        }
      });
    },
    editAfiliado(id, esAlta, convenio, esTitular) {
      this.newAfiTitular = false;
      this.$router.push({
        name: "EditAfiliado",
        params: {
          newAfiTitular: this.newAfiTitular,
          benId: id,
          fechaConsulta: this.fechaAltaAfiliado,
          esAlta: esAlta && this.canEdit,
          esTitular: esTitular,
          showConvenio: !(convenio == null || convenio == "Sin dato"),
          canEditVtoCredencial: this.canEditVtoCredencial
        }
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    },
    deleteAfiliado(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      const response = await this.deleteAfiliadoById({
        benId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
        this.loadingDeleteBtn = false;
      } else this.loadingDeleteBtn = false;
    },
    seeReferences(benId, benGrId) {
      this.$router.push({
        name: "DatosComplementariosAfiliado",
        params: {
          benId: benId,
          benGrId: benGrId,
          fechaConsulta: this.fechaAltaAfiliado,
          canSeeGestionMovimientos: this.canSeeGestionMovimientos
        }
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    },
    seeMovements(item) {
      this.movementsOn = true;
      this.benId = item.benId;
      this.afiliadoNom = item.nombre;
      if (item.estadoBenefId != 1) {
        this.isBaja = true;
      } else {
        this.isBaja = false;
      }
      this.movimientos.sort(function(a, b) {
        return a.id - b.id;
      });
    },
    seeOptions(item) {
      this.benId = item.benId;
      this.benGrId = item.benGrId;
      this.afiliadoNom = item.nombre;
      this.opciones.sort(function(a, b) {
        return a.id - b.id;
      });
    },
    toggleopenModalGenerarToken(benId, benNom) {
      this.benId = benId;
      this.afiliadoNom = benNom;
      this.openModalGenerarToken = true;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
</style>
